import { useState, useEffect, useRef } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import Image from "@tiptap/extension-image";
import TipTapLink from "@tiptap/extension-link";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from "./components/firebase";
import { getDatabase, ref, set, onValue, get, child } from "firebase/database";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { uniqueNamesGenerator, animals } from "unique-names-generator";
import "./styles.scss";
// import { HocuspocusProvider } from "@hocuspocus/provider";
// import * as Y from "yjs";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import iconLoading from "./images/loading.svg";

// get current URL path
// const location = window.location.pathname.replace("/", "");
// create a random id for the doc name
const docId = [...Array(8)]
  .map(
    () =>
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"[
        Math.floor(Math.random() * 62)
      ]
  )
  .join("");

const App = () => {
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  // const [usersList, setUsersList]: any = useState([]);
  // const [outputJSON, setOutputJSON]: any = useState();
  const [museLoading, setMuseLoading] = useState(false);
  const [museActive, setMuseActive] = useState(false);
  const [lastKeypressDate, setLastKeypressDate] = useState(Date.now());
  const museTimeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [showTipEscape, setShowTipEscape] = useState(true);
  const [userGaveMuseFeedback, setUserGaveMuseFeedback] = useState(false);
  const [settingAutomuse, setSettingAutomuse] = useState(false);
  const [settingMusePrompt, setSettingMusePrompt] = useState(
    "You're a professional writing coach. Your top objective is to help me write a post for Medium without writing it for me. Please give me two points of feedback. For the first point, ask me a question that will help me figure out what to write next. For the second point, give me succinct and insightful advice for how I can improve my article. Please don't repeat yourself. Please don't include any preamble. Please include only up to two points at a time, no more than that. Please don't number the points of feedback. Please put each point in its own paragraph. Thank you. Here's what I have so far:\n\n{{article}}"
  );
  const [settingMusePromptSelection, setSettingMusePromptSelection] = useState(
    "You're a professional writing coach. Your top objective is to help me write a post for Medium without writing it for me. You can give me writing advice for specific parts of my article that I tell you I need help with. Please don't repeat yourself. Please don't include any preamble or other advice, just give me one to three succinct pieces of advice, no more than that. Please don't number the pieces of advice. Please put each piece of advice in its own paragraph. I'd like advice about a specific part of my article. Here's the part I want advice for:\n\n\"{{selection}}\"\n\nHere's the full article:\n\n{{article}}"
  );

  // initialize modals
  const [modalTutorialOpen, setModalTutorialOpen] = useState(false);
  const [modalSettingsOpen, setModalSettingsOpen] = useState(false);

  // initialize Firebase
  const app = initializeApp(firebaseConfig);
  const database = getDatabase();
  let analytics;
  if (process.env.NODE_ENV === "production") {
    analytics = getAnalytics(app);
  }

  // login to firebase anonymously on page load
  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        console.log(`Error: ${error.code}: ${error.message}`);
      });
  });

  // check if logged in via firebase
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // user is signed in
      const uid = user.uid;
      setUserId(uid);
      if (userId && !userName) {
        const randomName = uniqueNamesGenerator({
          dictionaries: [animals],
          separator: " ",
          length: 1,
          style: "capital",
          seed: userId,
        });
        setUserName(randomName);
      }
    } else {
      console.log("User logged out");
    }
  });

  // initialize editor
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder:
            'Start writing here. For guidance from your writing coach, click the "Muse" button or press CMD + /',
        }),
        Typography,
        TipTapLink.configure({
          autolink: true,
        }),
        Image.configure({
          inline: true,
        }),
      ],
      autofocus: true,
      editable: true,
      onUpdate: ({ editor }) => {
        // update document content in the db
        const contentJSON = JSON.parse(JSON.stringify(editor.getJSON()));
        const refPath =
          "docs" +
          (process.env.NODE_ENV === "development" && "_dev") +
          "/" +
          docId;
        set(ref(database, refPath), {
          date_updated: new Date().toISOString(),
          textContent: editor.state.doc.textContent,
          content: contentJSON,
        });
      },
    },
    [userName]
  );

  // if the editor is empty, ensure the first line is a title
  useEffect(() => {
    if (!editor) return;

    const updateHandler = () => {
      const content = editor.getJSON().content;

      if (!content || content.length === 0) {
        // Insert a heading if the content is empty
        editor
          .chain()
          .insertContent({
            type: "heading",
            level: 1,
            content: [{ type: "text", text: "" }],
          })
          .run();
      } else {
        const hasEmptyHeading =
          content.length === 1 &&
          content[0].type === "heading" &&
          (!content[0].content ||
            !content[0].content[0] ||
            !content[0].content[0].text);

        if (hasEmptyHeading) {
          // Remove the empty heading so that the placeholder shows
          editor.commands.clearContent();
        } else if (content.length === 1 && content[0].type !== "heading") {
          // Convert the only node to heading if it's not already a heading
          editor.chain().setNode("heading", { level: 1 }).run();
        }
      }
    };

    editor.on("update", updateHandler);

    // Cleanup the event listener when the effect is cleaned up
    return () => {
      editor.off("update", updateHandler);
    };
  }, [editor]);

  // generate a writing prompt
  const muse = (editor) => {
    // don't generate another prompt if one is still loading
    if (museLoading) {
      return;
    }

    // get the user's selected text or the full text if nothing is selected
    let prompt = editor.view.state.selection.empty
      ? settingMusePrompt
      : settingMusePromptSelection;

    // replace {{article}} and {{selection}} with the user's full text and selected text, respectively
    prompt = prompt.replace("{{article}}", editor.state.doc.textContent);
    if (!editor.view.state.selection.empty) {
      prompt = prompt.replace(
        "{{selection}}",
        editor.view.state.selection.content().content.content[0].content
          .content[0].text
      );
    }

    // set loading state for Muse button
    setMuseLoading(true);

    // dismiss all open toasts
    toast.dismiss();

    // send prompt to OpenAI
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 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",
      },
    };
    const url = "https://api.openai.com/v1/chat/completions";
    console.log(`Prompt: ` + prompt);
    const data = {
      // model: "gpt-3.5-turbo",
      model: "gpt-4",
      messages: [
        {
          role: "user",
          content: prompt,
        },
      ],
    };
    axios
      .post(url, data, config)
      .then((res) => {
        // get the suggested text from openai
        const museText = res.data.choices[0].message.content + "\n\n";
        // dismiss all open toasts
        toast.dismiss();
        // show the writing prompt
        toast(museText);
        // end loading state
        setMuseLoading(false);
        setMuseActive(true);
        setUserGaveMuseFeedback(false);
      })
      .catch((err) => {
        console.log(err);
        // end loading state for Muse button
        setMuseLoading(false);
        setMuseActive(false);
      });
  };

  // detect full sentences and call muse function
  useEffect(() => {
    // log the current date vs last keypress date
    const timeSinceLastKeypress = Date.now() - lastKeypressDate;
    setLastKeypressDate(Date.now());

    // Get the text content of the editor
    const editorText = editor?.state.doc.textContent || "";

    // clear the timeout if the last keypress was in the last second
    if (timeSinceLastKeypress < 1000) {
      if (![" "].includes(editorText.slice(-1))) {
        clearTimeout(museTimeoutRef.current as number);
      }
    }

    // Check if the last character is a sentence-ending punctuation
    if ([".", "!", "?"].includes(editorText.slice(-1))) {
      // run muse after 1 second if user has automuse setting enabled
      if (settingAutomuse) {
        museTimeoutRef.current = setTimeout(() => {
          muse(editor);
        }, 1000);
      }
    }
  }, [editor?.state.doc.textContent]);

  // close any open toasts when user presses escape key
  const handleEscapeKeyPress = (event) => {
    if (event.key === "Escape") {
      museDismiss();
      setShowTipEscape(false);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, []);

  // close Muse advice toast
  const museDismiss = (id?: string) => {
    setMuseActive(false);
    toast.dismiss(id);
  };

  // listen for hotkeys
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "/" && e.metaKey) {
        muse(editor);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  // user clicked a feedback button on the Muse advice toast
  const museRespond = (editor, feedbackType) => {
    // emit an analytics event for this feedback
    if (process.env.NODE_ENV === "production") {
      const eventName = "feedback_" + feedbackType;
      logEvent(analytics, eventName, {
        value: feedbackType,
      });
    }

    // user asked for new feedback
    if (feedbackType === "retry") {
      muse(editor);
      toast.dismiss();
      return;
    }

    // user said the feedback was helpful or not helpful
    setUserGaveMuseFeedback(true);
    const contentJSON = JSON.parse(JSON.stringify(editor.getJSON()));
    const refPath =
      "feedback" +
      (process.env.NODE_ENV === "development" && "_dev") +
      "/" +
      docId +
      "-" +
      Date.now();

    // log the feedback in the database
    set(ref(database, refPath), {
      content: contentJSON,
      textContent: editor.state.doc.textContent,
      date_updated: new Date().toISOString(),
      feedback: feedbackType,
    });
  };

  return (
    <>
      {museActive && (
        <Toaster
          position="bottom-center"
          toastOptions={{
            className: "toaster",
            duration: 50000000,
            style: {},
            success: {
              duration: 3000,
            },
          }}
        >
          {(t) => (
            <ToastBar
              toast={t}
              style={{
                ...t.style,
                animation: t.visible
                  ? "custom-enter .5s ease"
                  : "custom-exit .5s ease",
                animationFillMode: "forwards",
              }}
            >
              {({ icon, message }) => (
                <>
                  <div className="toasterMessage">
                    {icon}
                    {message}
                    <div className="toasterActions">
                      <button
                        className="beckon"
                        onClick={() => museRespond(editor, "retry")}
                      >
                        <span>🔄</span>
                        <span>Retry</span>
                      </button>
                      <span className="toasterSep" />
                      {userGaveMuseFeedback ? (
                        <Fade>
                          <span className="toasterFeedback">
                            Thanks for the feedback!
                          </span>
                        </Fade>
                      ) : (
                        <>
                          <button
                            className="beckon"
                            onClick={() => museRespond(editor, "helpful")}
                          >
                            <span>👍</span>
                            <span>Helpful</span>
                          </button>
                          <button
                            className="beckon"
                            onClick={() => museRespond(editor, "not_helpful")}
                          >
                            <span>❌</span>
                            <span>Not helpful</span>
                          </button>
                        </>
                      )}
                    </div>
                    {showTipEscape && (
                      <div className="toasterTip">
                        <span>Tip: Press ESC to close</span>
                      </div>
                    )}
                  </div>
                  {t.type !== "loading" && (
                    <div className="toasterButtons">
                      <button
                        className="beckon"
                        onClick={() => museDismiss(t.id)}
                      >
                        X
                      </button>
                    </div>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      )}

      <div className="page">
        <Fade>
          <div className="header">
            <div className="logo beckon">
              <a href="/">✍️ Writing Lab</a>
            </div>
            <ul className="headerRight">
              <li className="beckon" onClick={() => setModalTutorialOpen(true)}>
                Tutorial
              </li>
              <li className="beckon" onClick={() => setModalSettingsOpen(true)}>
                Settings
              </li>
            </ul>
          </div>
        </Fade>

        {/* muse button at the bottom of the screen */}
        {!museLoading && !museActive && (
          <Fade delay={500}>
            <div className="museButtonWrapper">
              <div className="museButton" onClick={() => muse(editor)}>
                ✨ Muse ✨
              </div>
            </div>
          </Fade>
        )}

        {/* muse button loading state */}
        {museLoading && (
          <Fade>
            <div className="museButtonWrapper">
              <div className="museButton loading">
                <img src={iconLoading} alt="" onClick={() => muse(editor)} />
                <span>Musing</span>
              </div>
            </div>
          </Fade>
        )}

        {/* settings modal */}
        <Modal
          open={modalSettingsOpen}
          onClose={() => setModalSettingsOpen(false)}
          center
        >
          <h2>Settings</h2>
          <ul className="settings">
            {process.env.NODE_ENV !== "production" && (
              <>
                <li>
                  <label>Muse prompt (dev only)</label>
                  <textarea
                    className="musePrompt"
                    value={settingMusePrompt}
                    onChange={(e) => setSettingMusePrompt(e.target.value)}
                  />
                </li>
                <li>
                  <label>
                    Muse prompt when some text is selected (dev only)
                  </label>
                  <textarea
                    className="musePrompt"
                    value={settingMusePromptSelection}
                    onChange={(e) =>
                      setSettingMusePromptSelection(e.target.value)
                    }
                  />
                </li>
              </>
            )}
            <li className="checkbox">
              <input
                type="checkbox"
                id="settingAutomuse"
                checked={settingAutomuse}
                onChange={(e) => setSettingAutomuse(e.target.checked)}
              />
              <label htmlFor="settingAutomuse">
                Auto-muse after writing sentences
              </label>
            </li>
          </ul>
        </Modal>

        {/* welcome tutorial modal */}
        <Modal
          open={modalTutorialOpen}
          onClose={() => setModalTutorialOpen(false)}
          center
        >
          <h2>Welcome to the ✍️ Writing Lab</h2>
          <p>
            This is an experimental writing tool that aims to help you write at
            your best level — without writing a single word for you. It's a
            barebones text editor with a twist: it's inhabited by an AI writing
            coach named Muse. At any point while you're writing, you can ask
            Muse for feedback. There are two ways to do this:
          </p>
          <p>
            1. Press the "Muse" button in the editor.
            <br />
            2. Type Command + /
          </p>
          <p>
            If you select a sentence or paragraph before asking Muse for
            guidance, Muse will attempt to give you feedback on that specific
            selection. Otherwise, Muse will give you feedback on your entire
            document.
          </p>
          <p>
            Our goal is to learn if an AI-powered writing coach like Muse can
            actually help people write better. When Muse gives you advice,
            you'll see several feedback buttons. Please click the button that
            best describes how helpful you found Muse's feedback. Thank you! ✌️
          </p>
        </Modal>

        <div className="content">
          {userId && (
            <>
              {/* {usersList && (
                <ul className="users">
                  {usersList.map((user: any) => {
                    return (
                      <>
                        {userName !== "" && (
                          <li>
                            <Fade>
                              <img
                                className="avatar"
                                src={`https://avatars.dicebear.com/api/croodles-neutral/${user.name}.svg`}
                                alt=""
                              />
                              <span>{user.name}</span>
                            </Fade>
                          </li>
                        )}
                      </>
                    );
                  })}
                  <li>&nbsp;</li>
                </ul>
              )} */}

              <div className="editor">
                <Fade>
                  <div>
                    <EditorContent editor={editor} />
                  </div>
                </Fade>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
