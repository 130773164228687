// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKGUUa59dz2qalEgcB6PS3A2VJDeT6rV8",
  authDomain: "hyper-medium.firebaseapp.com",
  databaseURL: "https://hyper-medium-default-rtdb.firebaseio.com",
  projectId: "hyper-medium",
  storageBucket: "hyper-medium.appspot.com",
  messagingSenderId: "593981457109",
  appId: "1:593981457109:web:0e1ed691fb107572bbd85c"
};

export default firebaseConfig;